import * as React from "react";
import { InView } from "react-intersection-observer";

import {
  ArrowForward,
  Star,
  StarTwoTone,
  Lightbulb,
  Edit,
  Person,
  CheckCircle,
  Cancel,
  LocalHospital,
  MedicalServices,
  Medication,
  Article,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  alpha,
} from "@mui/material";

import StarRating from "../../../shared/StarRating";
import {
  SectionTitle,
  SubBenefits,
  BenefitTable,
  RegularBenefit,
  PlanDocuments,
  generateSubsection,
  DetailsAccordion,
  DetailsAccordionSummary,
  DetailsAccordionDetails,
} from "../DetailComponents";

import { formatUSD } from "../../../../utils/formatUSD";
import numberWithCommas from "../../../../utils/numberWithCommas";

import {
  newDentalDetails,
  newHearingDetails,
  Plan,
  newVisionDetails,
} from "../../../../types/Plan.types";
import { DoctorNetwork } from "../../../../types/Doctor.types";

const DRX_LOGO_URL = process.env.REACT_APP_DRX_LOGO_URL;

interface RootProps {
  plan: Plan;
  doctors: DoctorNetwork[] | null;
  isFavoritePlan: boolean;
  handleFavoritePlan: () => void;
  handleEnroll: () => void;
  handleScrollChange: (sectionId: string, endSection: boolean) => void;
  handleDrugCosts: () => void;
  handleNavigate: (location: "doctors" | "medicines") => void;
  handleAccordionTracking: (id: string) => void;
}

export default function MapdDetails({
  plan,
  doctors,
  isFavoritePlan,
  handleFavoritePlan,
  handleEnroll,
  handleScrollChange,
  handleDrugCosts,
  handleNavigate,
  handleAccordionTracking,
}: RootProps) {
  const [type, setType] = React.useState("Retail-30");
  const [PharmacyType, DaySupply] = type.split("-");

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const supplementalBenefitSections = () => {
    const sections = [
      {
        title: "Dental Coverages",
        subsections: generateSubsection(plan, newDentalDetails),
      },
      {
        title: "Vision Coverages",
        subsections: generateSubsection(plan, newVisionDetails),
      },
      {
        title: "Hearing Coverages",
        subsections: generateSubsection(plan, newHearingDetails),
      },
      {
        title: "Transportation",
        subsections: [
          {
            title: "Benefit",
            value: plan.PlanDetail?.InNetwork["Transportation"],
          },
        ],
      },
      {
        title: "Part B Giveback",
        subsections: [
          {
            title: "Amount",
            value: plan.PlanDetail?.InNetwork["PartBGiveback"] ?? "$0",
          },
        ],
      },
      {
        title: "Over-the-Counter Items",
        subsections: [
          {
            title: "Benefit",
            value: plan.PlanDetail?.InNetwork["OTC"],
          },
        ],
      },
      {
        title: "Fitness",
        subsections: [
          {
            title: "Benefit",
            value: plan.PlanDetail?.InNetwork["Fitness"],
          },
        ],
      },
    ];

    if (plan.PlanDetail?.InNetwork["FlexCard"]) {
      sections.push({
        title: "Flex Card",
        subsections: [
          {
            title: "Benefit",
            value: plan.PlanDetail?.InNetwork["FlexCard"],
          },
        ],
      });
    }
    return sections;
  };

  return (
    <Box
      width="100%"
      borderRadius={2}
      border="2px solid #efefef"
      padding={{ xs: 1, md: 3 }}
      sx={{ backgroundColor: "#fff" }}
    >
      <Grid container rowGap={4}>
        <Grid item xs={12} md={6} container direction="column" gap={1}>
          <Grid item>
            <Typography
              textAlign="center"
              sx={(theme) => ({
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                p: 0.5,
                width: 125,
                height: 32,
              })}
              fontWeight={600}
            >
              {plan.PlanYear}
            </Typography>
          </Grid>
          <Grid item>
            <img
              src={`${DRX_LOGO_URL}/${plan.LogoURL}`}
              alt=""
              loading="lazy"
              style={{
                objectFit: "contain",
                objectPosition: "left",
                maxWidth: "100%",
                maxHeight: "50px",
                width: "auto",
                height: "auto",
              }}
            />
          </Grid>
          <Grid item>
            <Typography fontWeight={700} variant="h5">
              {plan.PlanName}
            </Typography>
          </Grid>
          <Grid item>
            {plan.PlanRating ? (
              <StarRating rating={plan.PlanRating} />
            ) : (
              <Typography variant="body2">No Star Rating</Typography>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          alignItems="flex-end"
          justifyContent="flex-start"
          rowGap={1}
        >
          <Grid item container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: 10 }}
              onClick={() => handleEnroll()}
              endIcon={<ArrowForward />}
            >
              Enroll Now
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="inherit"
              startIcon={
                isFavoritePlan ? (
                  <Star color="secondary" />
                ) : (
                  <StarTwoTone color="secondary" />
                )
              }
              sx={{
                backgroundColor: "#cfcfcf",
                borderRadius: 15,
                "&:hover": {
                  backgroundColor: "#cfcfcf",
                  filter: "brightness(0.9)",
                },
              }}
              onClick={() => handleFavoritePlan()}
            >
              {isFavoritePlan && "Added "} Favorite
            </Button>
          </Grid>
        </Grid>

        <SectionTitle
          id="plan-highlights"
          title="Plan Highlights"
          subtitle="High level details to know about your plan"
          icon={<Lightbulb sx={{ fontSize: "3.5rem" }} color="secondary" />}
        />

        <InView
          threshold={0.9}
          onChange={(inView) => {
            if (inView) handleScrollChange("plan-highlights", false);
          }}
        >
          {({ ref }) => (
            <Grid
              ref={ref}
              item
              xs={12}
              md={10}
              container
              direction="column"
              pl={{ xs: 0, md: 10 }}
              rowGap={2}
            >
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <Typography fontWeight={600} variant="h6">
                    Monthly Plan Premium
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    borderBottom: "1.5px solid #efefef",
                    flexGrow: 1,
                    mx: 1,
                  }}
                />
                <Grid item>
                  <Typography fontWeight={600} variant="h6">
                    ${(plan.MedicalPremium + plan.DrugPremium)?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>

              <SubBenefits
                title="Maximum Out of Pocket"
                subsections={[
                  {
                    title: "In-Network",
                    value: `$${numberWithCommas(plan.MaximumOutOfPocketCost)}`,
                  },
                  // {
                  //   title: "Out-of-Network",
                  //   value: "-",
                  // },
                  // {
                  //   title: "Combined",
                  //   value: "-",
                  // },
                ]}
              />
              <SubBenefits
                title="Medical Deductible"
                subsections={[
                  {
                    title: "In-Network",
                    value: `$${numberWithCommas(plan.MedicalDeductible)}`,
                  },
                  // {
                  //   title: "Out-of-Network",
                  //   value: "-",
                  // },
                  // {
                  //   title: "Combined",
                  //   value: "-",
                  // },
                ]}
              />

              <SubBenefits
                title="Drug Deductible"
                subsections={[
                  {
                    title: "Annual",
                    value:
                      plan.PlanDetail?.InNetwork["DrugDeductibleExclusions"] ??
                      `$${numberWithCommas(plan.DrugDeductible)}`,
                  },
                  // {
                  //   title: "Drug Tiers",
                  //   value:
                  //     plan.PlanDetail?.InNetwork["DrugDeductibleExclusions"] ??
                  //     "-",
                  // },
                ]}
              />
            </Grid>
          )}
        </InView>

        <SectionTitle
          id="doctor-coverage"
          title="Doctor Coverage"
          subtitle="Determine if your Doctors are covered by this plan"
          icon={<Person sx={{ fontSize: "3.5rem" }} color="secondary" />}
        />

        <InView
          threshold={0.9}
          onChange={(inView) => {
            if (inView) handleScrollChange("doctor-coverage", false);
          }}
        >
          {({ ref }) => (
            <Grid
              ref={ref}
              item
              xs={12}
              md={10}
              container
              direction="column"
              pl={{ xs: 0, md: 10 }}
              rowGap={2}
            >
              <SubBenefits
                title="Primary Care Physician"
                subsections={[
                  {
                    title: "In-Network",
                    value: plan.PcpCost?.InNetwork,
                  },
                  {
                    title: "Out-of-Network",
                    value: plan.PcpCost?.OutOfNetwork,
                  },
                ]}
              />

              <SubBenefits
                title="Specialist"
                subsections={[
                  {
                    title: "In-Network",
                    value: plan.SpecialistCost?.InNetwork,
                  },
                  {
                    title: "Out-of-Network",
                    value: plan.SpecialistCost?.OutOfNetwork,
                  },
                ]}
              />

              <SubBenefits
                title="Your Inputted Doctors"
                icon={
                  <Button
                    variant="text"
                    color="secondary"
                    endIcon={<Edit />}
                    onClick={() => {
                      handleNavigate("doctors");
                    }}
                  >
                    Edit
                  </Button>
                }
                subsections={
                  doctors && doctors.length > 0
                    ? doctors.map((doctor) => {
                        const doctorInNetwork = doctor.location.networks?.find(
                          (network) => network.id === plan.ZelisNetwork
                        );
                        return {
                          title: doctor.facilityName
                            ? doctor.facilityName
                            : `Dr. ${doctor.firstName} ${doctor.lastName}`,
                          value: doctorInNetwork
                            ? "In-Network"
                            : "Out-of-Network",
                          icon: doctorInNetwork ? (
                            <CheckCircle color="primary" />
                          ) : (
                            <Cancel color="error" />
                          ),
                        };
                      })
                    : [{ title: "No Doctors Selected", value: "" }]
                }
              />
            </Grid>
          )}
        </InView>

        <SectionTitle
          id="inpatient-care"
          title="Inpatient Care"
          subtitle="See what Inpatient Care services your plan covers"
          icon={<LocalHospital sx={{ fontSize: "3.5rem" }} color="secondary" />}
        />

        <InView
          threshold={0.9}
          onChange={(inView) => {
            if (inView) handleScrollChange("inpatient-care", false);
          }}
        >
          {({ ref }) => (
            <Grid
              ref={ref}
              item
              xs={12}
              md={10}
              container
              direction="column"
              pl={{ xs: 0, md: 10 }}
              rowGap={2}
            >
              <BenefitTable
                sections={[
                  {
                    title: "Inpatient Hospital Care",
                    subsections: generateSubsection(plan, [
                      {
                        id: "InpatientHospitalCare",
                        title: "In-Network",
                        type: "InNetwork",
                      },
                      {
                        id: "InpatientHospitalCare",
                        title: "Out-of-Network",
                        type: "OutOfNetwork",
                      },
                    ]),
                  },
                  {
                    title: "Skilled Nursing Facility (SNF)",
                    subsections: generateSubsection(plan, [
                      {
                        id: "SNF",
                        title: "In-Network",
                        type: "InNetwork",
                      },
                      {
                        id: "SNF",
                        title: "Out-of-Network",
                        type: "OutOfNetwork",
                      },
                    ]),
                  },
                  {
                    title: "Inpatient Mental Healthcare",
                    subsections: generateSubsection(plan, [
                      {
                        id: "InpatientMentalHealthCare",
                        title: "In-Network",
                        type: "InNetwork",
                      },
                      {
                        id: "InpatientMentalHealthCare",
                        title: "Out-of-Network",
                        type: "OutOfNetwork",
                      },
                    ]),
                  },
                ]}
              />
            </Grid>
          )}
        </InView>

        <SectionTitle
          id="supplemental-benefits"
          title="Supplemental Benefits"
          subtitle="See what Supplemental Benefits your plan covers"
          icon={
            <MedicalServices sx={{ fontSize: "3.5rem" }} color="secondary" />
          }
        />

        <InView
          threshold={0.9}
          onChange={(inView) => {
            if (inView) handleScrollChange("supplemental-benefits", false);
          }}
        >
          {({ ref }) => (
            <Grid
              ref={ref}
              item
              xs={12}
              md={10}
              container
              direction="column"
              pl={{ xs: 0, md: 10 }}
              rowGap={2}
            >
              <RegularBenefit
                title="Dental"
                value={plan.Ancillary?.Dental ? "Included" : "Not Included"}
              />
              <RegularBenefit
                title="Vision"
                value={plan.Ancillary?.Vision ? "Included" : "Not Included"}
              />
              <RegularBenefit
                title="Hearing"
                value={plan.Ancillary?.Hearing ? "Included" : "Not Included"}
              />
              <RegularBenefit
                title="Transportation"
                value={
                  plan.Ancillary?.Transportation ? "Included" : "Not Included"
                }
              />
              <RegularBenefit
                title="Part B Premium Reduction"
                value={plan.Ancillary?.PartB ? "Included" : "Not Included"}
              />
              <RegularBenefit
                title="Over-the-Counter Items"
                value={plan.Ancillary?.OTC ? "Included" : "Not Included"}
              />
              <RegularBenefit
                title="Fitness Membership"
                value={plan.Ancillary?.Fitness ? "Included" : "Not Included"}
              />
            </Grid>
          )}
        </InView>

        <Grid
          item
          md={12}
          container
          direction="column"
          px={{ xs: 0, md: 4 }}
          rowGap={2}
        >
          <Grid item>
            <DetailsAccordion
              defaultExpanded
              onChange={(_event, expanded) => {
                if (expanded) {
                  handleAccordionTracking("Supplemental-Benefits");
                }
              }}
            >
              <DetailsAccordionSummary>
                Supplemental Benefit Details
              </DetailsAccordionSummary>
              <DetailsAccordionDetails>
                <BenefitTable sections={supplementalBenefitSections()} />
              </DetailsAccordionDetails>
            </DetailsAccordion>
          </Grid>

          <Grid item>
            <DetailsAccordion
              defaultExpanded
              onChange={(_event, expanded) => {
                if (expanded) {
                  handleAccordionTracking("Outpatient-Care-and-Services");
                }
              }}
            >
              <DetailsAccordionSummary>
                Outpatient Care and Services
              </DetailsAccordionSummary>
              <DetailsAccordionDetails>
                <BenefitTable
                  sections={[
                    {
                      title: "Acupuncture",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork["Acupuncture"] ?? "-",
                        },
                      ],
                    },
                    {
                      title: "Additional Services",
                      subsections: [
                        {
                          title: "Additional Services",
                          value:
                            plan.PlanDetail?.InNetwork["AdditionalServices"],
                        },
                        {
                          title: "Additional Telehealth",
                          value:
                            plan.PlanDetail?.InNetwork["AdditionalTelehealth"],
                        },
                        {
                          title: "Meal Benefit",
                          value: plan.PlanDetail?.InNetwork["Meal"],
                        },
                      ],
                    },
                    {
                      title: "Ambulance",
                      subsections: [
                        {
                          title: "Air",
                          value: plan.PlanDetail?.InNetwork["AirAmbulance"],
                        },
                        {
                          title: "Ground",
                          value:
                            plan.PlanDetail?.InNetwork["AmbulanceServices"],
                        },
                      ],
                    },
                    {
                      title: "Chiropractic Services",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork["ChiropracticServices"],
                        },
                      ],
                    },
                    {
                      title: "Diabetes Supplies and Services",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork[
                              "DiabetesSuppliesAndServices"
                            ],
                        },
                      ],
                    },
                    {
                      title:
                        "Diagnostic Tests, Lab and Radiology Services, and X-Rays",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork[
                              "DiagnosticTestsLabRadiology"
                            ],
                        },
                      ],
                    },
                    {
                      title: "Doctor Office Visits",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork["DoctorOfficeVisits"],
                        },
                      ],
                    },
                    {
                      title: "Doctor Specialty Visit",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork["DoctorSpecialtyVisits"],
                        },
                      ],
                    },
                    {
                      title: "Durable Medical Equipment",
                      subsections: [
                        {
                          title: "In-Network",
                          value: plan.PlanDetail?.InNetwork["DME"],
                        },
                      ],
                    },
                    {
                      title: "Emergency Care",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork["Emergency"] ??
                            plan.PlanDetail?.InNetwork["WorldwideEmergency"],
                        },
                      ],
                    },
                    {
                      title: "Home Health Care",
                      subsections: [
                        {
                          title: "In-Network",
                          value: plan.PlanDetail?.InNetwork["HomeHealthCare"],
                        },
                      ],
                    },
                    {
                      title: "Outpatient Mental Health Care",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork[
                              "OutpatientMentalHealthCare"
                            ],
                        },
                      ],
                    },
                    {
                      title: "Outpatient Prescription Drugs",
                      subsections: [
                        {
                          title: "Chemotherapy Drugs",
                          value:
                            plan.PlanDetail?.InNetwork[
                              "ChemotherapyRadiationDrugs"
                            ],
                        },
                      ],
                    },
                    {
                      title: "Outpatient Rehabilitation Services",
                      subsections: generateSubsection(plan, [
                        {
                          id: "CardiacRehabilitationServices",
                          title: "Cardiac",
                        },
                        {
                          id: "PulmonaryRehabilitationServices",
                          title: "Pulmonary",
                        },
                      ]),
                    },
                    {
                      title: "Outpatient Services/Surgery",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork[
                              "OutpatientServicesSurgery"
                            ],
                        },
                      ],
                    },
                    {
                      title: "Outpatient Substance Abuse",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork[
                              "OutpatientSubstanceAbuse"
                            ],
                        },
                      ],
                    },
                    {
                      title: "Podiatry Services",
                      subsections: [
                        {
                          title: "In-Network",
                          value: plan.PlanDetail?.InNetwork["PodiatryServices"],
                        },
                      ],
                    },
                    {
                      title:
                        "Preventive Services and Wellness/Education Programs",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork[
                              "PreventativeServices"
                            ] ?? "",
                        },
                      ],
                    },
                    {
                      title: "Prosthetic Devices",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork["ProstheticDevices"],
                        },
                      ],
                    },
                    {
                      title: "Renal Dialysis",
                      subsections: [
                        {
                          title: "In-Network",
                          value: plan.PlanDetail?.InNetwork["RenalDialysis"],
                        },
                      ],
                    },

                    {
                      title: "Urgently Needed Care",
                      subsections: [
                        {
                          title: "In-Network",
                          value:
                            plan.PlanDetail?.InNetwork["UrgentCare"] ?? "-",
                        },
                      ],
                    },
                  ]}
                />
              </DetailsAccordionDetails>
            </DetailsAccordion>
          </Grid>
        </Grid>

        <SectionTitle
          id="prescription-benefits"
          title="Prescription Benefits"
          subtitle="What you may pay for your drug coverage"
          icon={<Medication sx={{ fontSize: "3.5rem" }} color="secondary" />}
        />

        <InView
          threshold={0.9}
          onChange={(inView) => {
            if (inView) handleScrollChange("prescription-benefits", false);
          }}
        >
          {({ ref }) => (
            <Grid
              ref={ref}
              item
              xs={12}
              md={10}
              container
              direction="column"
              pl={{ xs: 0, md: 10 }}
              rowGap={2}
            >
              <RegularBenefit
                title="Drug Deductible"
                value={
                  plan.PlanDetail?.InNetwork["DrugDeductibleExclusions"] ??
                  `$${numberWithCommas(plan.DrugDeductible)}`
                }
              />
              <RegularBenefit
                title="Est. Monthly Drug Costs"
                value={
                  plan.PlanDrugCoverage?.length > 0 &&
                  plan.EstimatedMonthlyDrugCosts !== undefined
                    ? `${formatUSD(
                        plan.EstimatedMonthlyDrugCosts - plan.DrugPremium
                      )} per Month`
                    : "N/A"
                }
              />
              <RegularBenefit
                title="Pharmacy Type"
                value={
                  plan.PharmacyCosts[0]?.IsPreferred
                    ? "Preferred Cost-Sharing Pharmacy"
                    : "Standard Cost-Sharing Pharmacy"
                }
              />

              <SubBenefits
                title="Your Drug List"
                icon={
                  <Button
                    variant="text"
                    color="secondary"
                    endIcon={<Edit />}
                    onClick={() => {
                      handleNavigate("medicines");
                    }}
                  >
                    Edit
                  </Button>
                }
                subsections={
                  plan.PlanDrugCoverage && plan.PlanDrugCoverage.length > 0
                    ? plan.PlanDrugCoverage.map((drugCoverage) => {
                        const drugCovered = drugCoverage.TierNumber > 0;
                        return {
                          title: drugCoverage.LabelName.toUpperCase(),
                          value: drugCovered ? "Covered" : "Not Covered",
                          icon: drugCovered ? (
                            <CheckCircle color="primary" />
                          ) : (
                            <Cancel color="error" />
                          ),
                        };
                      })
                    : [
                        {
                          title: "No Drugs Selected",
                          value: "",
                        },
                      ]
                }
              />
              <Grid item container justifyContent="flex-end">
                <Button
                  variant="text"
                  color="secondary"
                  endIcon={<ArrowForward />}
                  onClick={() => handleDrugCosts()}
                  disabled={
                    plan.PlanDrugCoverage && plan.PlanDrugCoverage.length === 0
                  }
                >
                  View Drug Costs
                </Button>
              </Grid>

              <Grid item container height={40}>
                <Select
                  value={type}
                  onChange={handleTypeChange}
                  variant="standard"
                >
                  <MenuItem value={"Retail-30"}>30 Day - Retail</MenuItem>
                  <MenuItem value={"Retail-90"}>90 Day - Retail</MenuItem>
                  <MenuItem value={"MailOrder-30"}>
                    30 Day - Mail-Order
                  </MenuItem>
                  <MenuItem value={"MailOrder-90"}>
                    90 Day - Mail-Order
                  </MenuItem>
                </Select>
              </Grid>

              <SubBenefits
                title="Drug Tier Pricing - Preferred Pharmacy"
                subsections={
                  plan.DrugTier
                    ? Object.keys(
                        //@ts-ignore
                        plan.DrugTier.Preferred[PharmacyType][DaySupply]
                      ).map((key) => {
                        const value =
                          //@ts-ignore
                          plan.DrugTier.Preferred[PharmacyType][DaySupply][key];
                        return {
                          title: `Tier ${key}`,
                          value,
                        };
                      })
                    : []
                }
              />

              <SubBenefits
                title="Drug Tier Pricing - Standard Pharmacy"
                subsections={
                  plan.DrugTier
                    ? Object.keys(
                        //@ts-ignore
                        plan.DrugTier.Standard[PharmacyType][DaySupply]
                      ).map((key) => {
                        const value =
                          //@ts-ignore
                          plan.DrugTier.Standard[PharmacyType][DaySupply][key];
                        return {
                          title: `Tier ${key}`,
                          value,
                        };
                      })
                    : []
                }
              />
            </Grid>
          )}
        </InView>

        <SectionTitle
          id="plan-documents"
          title="Plan Documents"
          subtitle="Documents related to the plan"
          icon={<Article sx={{ fontSize: "3.5rem" }} color="secondary" />}
        />

        <InView
          threshold={1}
          onChange={(inView) => {
            if (inView) {
              handleScrollChange("plan-documents", true);
            }
          }}
        >
          {({ ref }) => (
            <Grid
              ref={ref}
              item
              xs={12}
              md={10}
              display="inline-flex"
              flexWrap="wrap"
              justifyContent="center"
              pl={{ xs: 0, md: 10 }}
              gap={2}
            >
              <PlanDocuments
                plan={plan}
                documents={
                  plan.Documents
                    ? plan.Documents.map((document) => {
                        return {
                          title: document.LinkName,
                          link: document.Url,
                        };
                      })
                    : []
                }
              />
            </Grid>
          )}
        </InView>
      </Grid>
    </Box>
  );
}
